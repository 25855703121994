<template>
    <div>
       <pm-Card>
            <template v-slot:title>
                Confirmação
            </template>
            <template v-slot:content>
                <div class="p-fluid formgrid grid" style="font-size:12px">
                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">CPF: &nbsp; <b>{{ info.cpf ? info.cpf : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Matrícula: &nbsp; <b>{{ info.matricula ? info.matricula : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Nome: &nbsp; <b>{{ info.nome ? info.nome : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <!--<div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Escolaridade: &nbsp; <b>{{ info.escolaridade ? info.escolaridade : ' Não Informado '}} </b>
                        </label>
                    </div>-->

                    <!--<div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Função: &nbsp; <b>{{ info.funcao.nome ? info.funcao.nome : ' Não Informado '}} </b>
                        </label>
                    </div>-->

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Possui deficiência: &nbsp; <b>{{ (info.possuiDeficiencia == true ? "sim" : "não")}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-10 lg:col-10" style="margin:0px;">
                        <label for="class">Deficiência: &nbsp; <b>{{ info.deficiencia ? info.deficiencia : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-12 lg:col-12" style="margin:0px;">
                        <label for="class">Observação: &nbsp; <b>{{ info.observacao ? info.observacao : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-12 lg:col-12" style="margin:0px;">
                        <label for="class">Email: &nbsp; <b>{{ info.email ? info.email : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">CEP: &nbsp; <b>{{ info.cep ? info.cep : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-3 lg:col-3" style="margin:0px;">
                        <label for="class">Logradouro: &nbsp; <b>{{ info.logradouro ? info.logradouro : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Número: &nbsp; <b>{{ info.numero ? info.numero : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Complemento: &nbsp; <b>{{ info.complemento ? info.complemento : ' Não Informado '}} </b>
                        </label>
                    </div>


                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Bairro: &nbsp; <b>{{ info.bairro ? info.bairro : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Cidade: &nbsp; <b>{{ info.cidade ? info.cidade : ' Não Informado '}} </b>
                        </label>
                    </div>

                    <div class="field col-12 md:col-2 lg:col-2" style="margin:0px;">
                        <label for="class">Estado: &nbsp; <b>{{ info.estado ? info.estado : ' Não Informado '}} </b>
                        </label>
                    </div>

                </div>
            </template>
            <template v-slot:footer>
                <div class="grid grid-nogutter justify-content-between">
                    <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <pm-Button label="Enviar" @click="enviarDados()" icon="pi pi-check" iconPos="right" class="p-button-success"/>
                </div>
            </template>
        </pm-Card>
    </div>
</template>


<script>

export default {
    props: {
        dadosPessoa: { type: Array, default: () => [] },
        pg: {}
    },
    data () {
        return {
            info: {
                nome: '',
                possuiDeficiencia: false,
                deficiencia: '',
                nee:false,
                observacao:'',
                email:'',
                senha:'',
                matricula:null,
                rg:'',
                dataEmissao:'',
                cpf:'',
                sexo:'',
                cep:'',
                logradouro:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                telefone:[],
            },
            cepInfo:'',
            submitted: false,
            validationErrors: {}
        }
    },
    methods: {
        prevPage(info) {
            this.$emit('prevPage', {info: this.info});
        },
        enviarDados(info) {
            this.$emit('enviarDados', {info: this.info});
        },
    },
    beforeMount() {
        this.info.nome =   (this.dadosPessoa.nome != undefined ? this.dadosPessoa.nome : this.info.nome);
        this.info.sobrenome =   (this.dadosPessoa.sobrenome != undefined ? this.dadosPessoa.sobrenome : this.info.sobrenome);
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia =  this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.email =  this.dadosPessoa.email;
        this.info.senha =  this.dadosPessoa.senha;
        this.info.confirmarSenha =  this.dadosPessoa.confirmarSenha;

        this.info.cpf =  this.dadosPessoa.cpf;
        this.info.cpfDele = (this.dadosPessoa.cpfDele != undefined ? this.dadosPessoa.cpfDele : '');

        this.info.cep =  (this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep);
        this.info.estado =  this.dadosPessoa.estado;
        this.info.cidade =  this.dadosPessoa.cidade;
        this.info.bairro =  this.dadosPessoa.bairro;
        this.info.logradouro =  this.dadosPessoa.logradouro;
        this.info.numero =  this.dadosPessoa.numero;
        this.info.complemento =  this.dadosPessoa.complemento;
        this.info.telefone =  (this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone);
        this.info.sexo =  (this.dadosPessoa.sexo != undefined ? this.dadosPessoa.sexo : this.info.sexo );
        this.info.foto =  this.dadosPessoa.foto; //(this.dadosPessoa.foto != undefined ? this.dadosPessoa.foto : this.info.foto);
        this.info.id =  (this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id);
        this.info.matricula =  this.dadosPessoa.matricula;
        this.info.escolaridade =  this.dadosPessoa.escolaridade;
        this.info.funcao = this.dadosPessoa.funcao;
        this.info.login_id = this.dadosPessoa.login_id;
        this.info.emailDele = this.dadosPessoa.emailDele;

    },
}
</script>
